import React, { useEffect, useRef } from "react";
import { getCursorFromDocumentIndex } from "gatsby-source-prismic-graphql";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from "styles/structure";
import Layout from "components/Layout";
import SEO from "components/SEO";
import Hero from "components/Hero";
import InsightsGrid from "components/_grid/Insights";
import InsightCard from "components/_card/Insight";
import CleanInput from "components/_ui/CleanInput";
import NewsletterSubscribe from "components/NewsletterSubscribe";

const InsightsContainer = styled.section`
  padding: 8rem 0;

  header {
    & + * {
      margin-top: 4.8rem;
    }

    div {
      margin-top: 1.6rem;
    }
  }

  p {
    max-width: 74rem;
  }
`;

const Newsletter = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.red600};
  color: #fff;
  height: 100%;
  width: 100%;
  margin: auto;
  font-size: 3.2rem;
  padding: 6.4rem 2.4rem;

  h4 {
    color: #fff;
    padding-bottom: 1rem;
    font-size: 3.2rem;
  }

  p.insight-landing-subscribe-text {
    font-size: 1.4rem;
    display: inline-block;
    margin-bottom: 6rem;
  }


  form {
    position: relative;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 8.6rem 2.4rem 6.4rem;
    justify-content: flex-end;
    text-align: center;

    h4 {
      font-size: 4.2rem;
      padding-bottom: 4vw;
    }

    p.insight-landing-subscribe-text {
      display: none;
    }

    form {
      margin-top: 1.2rem;
    }
  }

  @media (min-width: ${dimensions.desktopTransitionUp}px) {
    min-height: 40rem;
  }
`;

const InsightsMore = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  min-height: 20rem;

  &::after {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${colors.red600};
    cursor: pointer;
    z-index: 1;
  }

  h4 {
    color: #fff;
  }

  @media (min-width: ${dimensions.desktopTransitionUp}px) {
    min-height: 40rem;
  }
`;

export default props => {
  //Required check for no data being returned
  const landing = props.data.prismic.allInsightss.edges[0].node;
  const settings = props.data.prismic.allSite_settingss.edges;

  if (!landing) return null;

  const limit = 9;
  const didMountRef = useRef(false);
  const initialLoaded = useRef(false);
  const [page, setPage] = React.useState(8);
  const [data, setData] = React.useState(props.data.prismic.allInsights);
  const [insights, setInsights] = React.useState(data.edges);

  const onLoadMore = () => {
    props.prismic
      .load({ variables: { after: getCursorFromDocumentIndex(page) } })
      .then(res => {
        setData(res.data.allInsights);
        setInsights(a => a.concat(res.data.allInsights.edges));
      });

    setPage(page + limit);
  };

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    if(!initialLoaded) {
      this.onLoadMore();

      initialLoaded.current = true;
    }

  }, [props, page]);

  return (
    <Layout headerStyle={"dark"}>
      <SEO
        title={landing.social_title || "Our Insights"}
        description={
          landing.social_description ? landing.social_description : null
        }
        image={landing.social_image ? landing.social_image.url : null}
      />

      <Hero title={landing.introduction_heading} />

      <InsightsContainer>
        <Inner>
          <InsightsGrid layout="full">
            {insights.map((insight, i) => {
              if (i === 5) {
                return (
                  <>
                    <li key={i}>
                      <InsightCard
                        destination={`/insight/${insight.node._meta.uid}`}
                        image={insight.node.feature_image}
                        title={insight.node.title}
                        category={insight.node.category}
                      />
                    </li>

                    <Newsletter>
                      <h4>
                        Sign Up for <br />
                        Our Newsletter
                      </h4>
                      {settings.length > 0 && (
                        <p className='insight-landing-subscribe-text'>{settings[0].node.insight_landing_subscribe_text}</p>
                      )}
                      <NewsletterSubscribe variant="insight" />
                    </Newsletter>
                  </>
                );
              } else {
                return (
                  <li key={i}>
                    <InsightCard
                      destination={`/insight/${insight.node._meta.uid}`}
                      image={insight.node.feature_image}
                      title={insight.node.title}
                      category={insight.node.category}
                    />
                  </li>
                );
              }
            })}

            {data.pageInfo.hasNextPage && (
              <li>
                <InsightsMore>
                  <CleanInput as="button" onClick={onLoadMore}>
                    <h4>View more</h4>
                  </CleanInput>
                </InsightsMore>
              </li>
            )}
          </InsightsGrid>
        </Inner>
      </InsightsContainer>
    </Layout>
  );
};

export const query = graphql`
  query getInsights($first: Int = 9, $after: String) {
    prismic {
      allSite_settingss {
        edges {
          node {
            insight_landing_subscribe_text
          }
        }
      }
      allInsightss {
        edges {
          node {
            introduction_heading
            introduction_content
            social_title
            social_description
            social_image
          }
        }
      }
      allInsights(first: $first, after: $after, sortBy: publish_date_DESC) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            _meta {
              uid
            }
            title
            category {
              ... on PRISMIC_Category {
                name
              }
            }
            feature_image
          }
        }
      }
    }
  }
`;
